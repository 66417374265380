<template>
  <v-card class="pa-0 ma-0 " flat :color="essayGreen" min-height="350px">
    <v-card-text class="text-center">
      <div class="mt-3">
        <v-icon size="70">mdi-check-circle</v-icon>
        <div>
          <span class="text-h4"> Payment Successful</span>
        </div>
        <div class="mt-3">
          Your Payment has been successful, Will Update your running balance in a few!
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="mt-10 mr-5">
      <v-spacer></v-spacer>
      <v-btn class="pa-5 successful" light @click="emit('close')"> Proceed <v-icon>mdi-arrow-right</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "successful",
  mixins: [colorMxn]
}
</script>