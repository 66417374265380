<template>
  <v-dialog
    v-model="show"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
    width="400"
  >
    <v-card :color="dark">
      <v-container class="">

        <v-card color="transparent" flat class="pa-0">
          <v-card-title class="pa-0">
          <span class="text-h4"><strong>Add funds to your account</strong></span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            tile
            :color="modalColor"
            class="rounded-lg"
            outlined
            @click="$emit('close', false)"
          >
            <v-icon class="white--text">
              mdi-close
            </v-icon>
          </v-btn>
          </v-card-title>
        </v-card>
          <v-row class="mt-10" style="border: 1px solid grey; border-width: thin; border-radius: 4px;">
            <v-col  class="pa-0" >
              <v-form ref="form" v-model="valid"  lazy-validation v-if="!charged">
                <v-card class="pa-0 ma-0" flat :color="ant" min-height="300px">
                  <v-card-text>
                    <div style="height: 260px;">
                      <label class="text-h6"> Amount (USD):</label>
                      <v-text-field
                        v-model="amount"
                        outlined
                        background-color="transparent"
                        solo
                        class="text-h5 mt-2"
                        placeholder="0.00"
                        :rules="amountRules()"
                        prepend-inner-icon="mdi-currency-usd"
                      >
                      </v-text-field>
                    </div>
                  </v-card-text>
                  <v-card-actions class="pb-5 mr-2">
                    <v-spacer></v-spacer>
                    <v-btn :color="essayGreen" :disabled="!valid" @click="createTransaction" :loading="loading"> Proceed 
                      <v-icon class="ml-3">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
              <div v-else>
                <Successful @close="proceedMethod" />
                <FailedView @close="charged = false" />
                <!-- <v-card </Successful>class="pa-0 ma-0 " flat :color="essayGreen" min-height="350px" v-if="successful">
                  <v-card-text class="text-center">
                    <div class="mt-3">
                      <v-icon size="70">mdi-check-circle</v-icon>
                      <div>
                        <span class="text-h4"> Payment Successful</span>
                      </div>
                      <div class="mt-3">
                        Your Payment has been successful, Will Update your running balance in a few!
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions class="mt-10 mr-5">
                    <v-spacer></v-spacer>
                    <v-btn class="pa-5 successful" light @click="proceedMethod"> Proceed <v-icon>mdi-arrow-right</v-icon></v-btn>
                  </v-card-actions>
                </v-card> -->
                <!-- <v-card class="pa-0 ma-0 " flat :color="ant" min-height="350px" v-if="!successful">
                  <v-card-text class="text-center">
                    <div class="mt-10">
                      <v-icon class="mt-5" color="red" x-large>mdi-close</v-icon>
                      <div>
                        <span class="text-h4 red--text"> Payment Failed !</span>
                      </div>
                      <div class="mt-3">
                        Your Transactions was not successful, Please try again.
                      </div>
                    </div>
                    
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="charged = false"> Try Again</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card> -->
              </div>
            </v-col>

            <v-col>
              <v-card flat :color="dark">
                <div class="text-center mt-5">
                  <i class="fas fa-credit-card fa-8x"></i>
                  <h3 class="mt-5"> Make a secure payment</h3>
                  <P class="mt-5 text--disabled">
                    We use advanced 256 bit encryption to protect your information and securely process all transactions.
                  </P>
                </div>

                <div class="text-center mt-5">
                  <span class="text--disabled">
                    Certified By:
                  </span>
                  <div>
                    <i class="fab fa-cc-visa fa-lg"></i>
                    <i class="fab fa-cc-mastercard fa-lg ml-2"></i>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin"
import Successful from './components/Successful';
import FailedView from './components/FailedView';


export default {
  name: "PaymentModal",
  components: {
    Successful,
    FailedView,
  },
  props: ["show", 'description', 'minimumAmount'],
  mixins: [colorMxn, validationMxn],
  data() {
    return {
      message: '',
      actionClass: 'success',
      loading: false,
      amount: '',
      valid: true,
      charged: false,
      successful: false,
    }
  },
  computed: {
    ...mapGetters(['getSession', 'getReload']),
  },
  mounted() {
    this.amount = this.minimumAmount;
  },
  methods: {
    ...mapMutations(['setReload']),
    ...mapActions(['performPostActions', 'refreshAuthToken', 'getBalance']),
    async getTokenRefresh(data) {
      if (data.status === 'successful') {
        await this.getBalance();
      }
    },
    async createTransaction() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const payload = {
        amount: this.amount,
        description: this.description,
        transaction_type: 'deposit',
        transaction_type_title: 'credit',
        customer_id: this.getSession.customer.id,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/transaction/store'
      };
      
      const response = await this.performPostActions(fullPayload);
      if (response.status) {
        this.makePayment(response.data);
      }
      this.loading = false;
    },
    async updateTransaction(id) {
      const payload = {
        status: 'failed',
      };

      const fullPayload = {
        params: payload,
        endpoint: `/transaction/update/${id}`
      };
      
     await this.performPutActions(fullPayload);
    },
    makePayment(data) {
      this.loading = true;
      let handler = window.PaystackPop.setup({
        key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
        ref: data.id,
        amount: this.amount,
        currency: "USD",
        channels: ["card"],
        metadata: {
          email: this.getSession.email,
          // email: "victormisiko.vm@gmail.com",
          phone_number: this.getSession.phone_number,
          name: this.getSession.username,
        },
        callback: (data1) => { 
          this.successful = data1.status === "successful";
          this.charged = true;
          this.$emit('callback', data1);
          // this.closePaymentModal();
        },
        onClose: (incomplete) => {
          console.log(incomplete);
          // this.closePaymentModal();
          this.updateTransaction(data.id);
          // if (incomplete === true) {
          //   this.updateTransaction(data.id);
          // }
        },
        // customizations: {
        //   title: "Essay Genius",
        //   description: this.description,
        // },
      });
      handler.openIframe();
      this.loading = false;
    },
    closePaymentModal() {
      document.getElementsByName('checkout')[0].setAttribute('style', 'position:fixed;top:0;left:0;z-index:-1;border:none;opacity:0;pointer-events:none;width:100%;height:100%;');
      document.body.style.overflow = '';
    },
    amountRules() {
      const minimumAmount = this.minimumAmount ? this.minimumAmount : 0.00;
      return [
        v => !!v || "Amount is required",
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || "Only digits are required",
        v => v > 0 || "Amount: Should be greator than 0.01",
        v => v >= parseInt(minimumAmount) || `Amount: Should be greator than ${minimumAmount}`
      ]
    },
    proceedMethod() {
      this.$emit('close', false);
      this.charged = false;
    },
  }
  
}
</script>

<style scoped>
.pending {
  color: #286bb8;
}

.successful {
  color: #038c43;
}

.failed {
  color: red;
}

</style>