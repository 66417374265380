<template>
   <v-card class="pa-0 ma-0 " flat :color="ant" min-height="350px" >
    <v-card-text class="text-center">
      <div class="mt-10">
        <v-icon class="mt-5" color="red" x-large>mdi-close</v-icon>
        <div>
          <span class="text-h4 red--text"> Payment Failed !</span>
        </div>
        <div class="mt-3">
          Your Transactions was not successful, Please try again.
        </div>
      </div>
      
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="$emit('close')"> Try Again</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "FailedView",
  mixins: [colorMxn]
}
</script>